import React from 'react'
import Helmet from 'react-helmet'
import Contact from '../components/Contact'
import Layout from '../components/layout'
import Address from '../components/Address'

const Page = () => {
  const siteTitle = 'Psychoterapia Mosty - ośrodek psychoterapii we Wrocławiu'
  const siteDescription =
    'Psychoterapia Wrocław, doświadczeni psychoterapeuci. Psychoterapia indywidualna, terapia par oraz terapia rodzinna.'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>Ośrodek psychoterapii MOSTY</h2>
          </header>
        </section>

        <section>
          <p>
            <strong>MOSTY</strong> jako konstrukcja architektoniczna mają za
            zadanie łączyć niepołączone kawałki. Tak właśnie rozumiem
            psychoterapię. W każdym z nas są części, które mimo tego, że
            istnieją obok siebie nigdy nie zostały połączone. Dzięki procesowi
            psychoterapeutycznemu możemy w bezpiecznych warunkach odkrywać nasze
            najtrudniejsze aspekty ludzkiej natury, badać je oraz integrować
            jako własne.
          </p>

          <p>
            <strong>MOSTY</strong> są też elementem jakieś większej drogi,
            podróży. To właśnie doświadczenie pracy w różnych zespołach oraz
            placówkach doprowadziło mnie do momentu, w którym postanowiłam
            założyć własne miejsce. <strong>MOSTY</strong> powstały z
            połączeniem pasji, wiedzy, doświadczenia oraz zamiłowania do pracy w
            zespole. Zapraszając do współpracy ludzi, którzy od lat zajmują się
            psychoterapią, nieustannie się szkolą oraz zachowują standardy
            etyczne mam pewność, że Ośrodek będzie miejscem żywej wymiany myśli
            oraz doświadczeń.
          </p>

          <p>
            Każdy z członków zespołu Ośrodka przeszedł własne wieloletnie
            szkolenie psychoterapeutyczne oraz własną psychoterapię. Regularnie
            szkolimy się, uczestniczymy w konferencjach oraz poddajemy pracę
            własną superwizji.
          </p>

          <p style={{ textAlign: 'right', fontStyle: 'italic', margin: 0 }}>
            Jadwiga Wojciechowska
          </p>
        </section>
        <section id="three">
          <Address />
        </section>
      </div>
    </Layout>
  )
}

export default Page
