import React, { useState } from 'react'

const Address = ({ id }) => {
  return (
    <>
      <div className="address">
        Ośrodek psychoterapii MOSTY
        <br />
        ul. Wyszyńskiego 138/6, 50-307 Wrocław,{' '}
        <a href="https://goo.gl/maps/56nht7gDMMzNmwVy7" target="_blank">
          Google Maps
        </a>
      </div>
    </>
  )
}
export default Address
