import React, { useState } from 'react'

const Contact = ({ id }) => {
  const [rand] = useState(id || Math.random().toString(36).substring(7))
  let name = 'kontakt-' + id

  return (
    <>
      <h2>Kontakt</h2>

      <div className="row">
        <div className="8u 12u$(small)">
          <form
            action="/formularz-wyslany"
            name={name}
            method="POST"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value={name} />
            <div className="row uniform 50%">
              <div className="6u 12u$(xsmall)">
                <input
                  type="text"
                  name="imie"
                  id="name"
                  placeholder="Imię, Nazwisko"
                  required
                />
              </div>
              <div className="6u 12u$(xsmall)">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="12u">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Wiadomość"
                  rows="4"
                  required
                ></textarea>
              </div>
            </div>
            <ul className="actions" style={{ marginTop: 30 }}>
              <li>
                <input type="submit" value="Wyślij wiadomość" />
              </li>
            </ul>
          </form>
        </div>
        <div className="4u 12u$(small)">
          <ul className="labeled-icons">
            <li>
              <h3 className="icon fa-home">
                <span className="label">Adres</span>
              </h3>
              ul. Wyszyńskiego 138/6
              <br />
              50-307 Wrocław
              <br />
              <a
                href="https://goo.gl/maps/56nht7gDMMzNmwVy7"
                alt="Google Maps"
                target="_blank"
              >
                Google Maps
              </a>
            </li>
            <li className="contact">
              <h3 className="icon fa-mobile">
                <span className="label">Phone</span>
              </h3>
              <span className="person">
                <strong>Jadwiga Wojciechowska</strong>
                <br /> 510 537 384
              </span>
              <span className="person">
                <strong>Alicja Kotowska</strong>
                <br /> 697 622 638
              </span>
              <span className="person">
                <strong>Katarzyna Szuba</strong>
                <br /> 507 129 798
              </span>
              <span className="person">
                <strong>Joanna Szymańska</strong>
                <br /> 732 687 682
              </span>
              <span className="person">
                <strong>Agata Świst</strong>
                <br /> 609 477 247
              </span>
            </li>
            {/* <li>
              <h3 className="icon fa-envelope-o">
                <span className="label">Email</span>
              </h3>
              <a href="#">hello@untitled.tld</a>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  )
}
export default Contact
